<template>
  <div class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate @keydown.enter.prevent>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          สร้างใบปรับสต๊อก
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!-- -->
        <div class="row d-flex justify-content-center">
          <div class="col-sm-6">
            <label for="selectMainProduct" class="form-label">เลขที่</label>
            <input disabled class="form-control mb-4" />
          </div>

          <div class="col-sm-6">
            <label for="selectMainProduct" class="form-label"
              >วันที่เอกสาร</label
            >
            <!-- <input
              v-model="form.document_date"
              type="date"
              disabled
              class="form-control mb-4"
            /> -->
            <a-date-picker
              class="form-control ant-date"
              disabled
              v-model:value="date"
              format="DD/MM/YYYY"
            />
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <label for="selectMainProduct" class="form-label col-sm-12"
            >ผู้ขอตัดสต๊อก</label
          >
          <div class="col-sm-12">
            <input v-model="userStock" disabled class="form-control" />
          </div>
        </div>

        <div class="row text-end">
          <div class="col-sm-12">
            <button
              type="button"
              class="btn btn-sm btn-light-primary"
              style="font-size: 14px; font-weight: bold"
              @click="addItem"
            >
              เพิ่ม
            </button>
            <AddItem
              :dialogAddItem="dialogAddItem"
              :dataEditItem="dataEditItem"
              @submitEditItem="submitEditItem"
              @submitAddItem="submitAddItem"
              @closeDialogAddItem="closeDialogAddItem"
            />
          </div>
        </div>

        <div class="table-responsive" style="padding-top: 3px">
          <table
            class="table table-hover table-rounded table-striped border gy-7 gs-7"
          >
            <thead>
              <tr
                style="width: 100%"
                class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200 mw-200px"
              >
                <th>#</th>
                <th>Item code</th>
                <th>Item name</th>
                <th>Qty</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Vat</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in form.products" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.product_code }}</td>
                <td>{{ item.product_name }}</td>
                <td>{{ item.item_amt }}</td>
                <td>{{ formatPrice(item.product_retail_price) }}</td>
                <td>
                  {{ formatPrice(item.item_discount ? item.item_discount : 0) }}
                </td>
                <td>
                  <!-- {{
                    formatPrice(
                      ((parseInt(
                        item.product_retail_price
                          ? item.product_retail_price
                          : 0
                      ) -
                        parseInt(item.item_discount ? item.item_discount : 0)) *
                        parseInt(item.item_amt ? item.item_amt : 0) *
                        7) /
                        100
                    )
                  }} -->
                  7%
                </td>
                <td>
                  {{
                    // ((parseInt(
                    //   item.product_retail_price ? item.product_retail_price : 0
                    // ) -
                    //   parseInt(item.item_discount ? item.item_discount : 0)) *
                    //   parseInt(item.item_amt ? item.item_amt : 0) *
                    //   7) /
                    //   100 +
                    formatPrice(
                      (parseInt(
                        item.product_retail_price
                          ? item.product_retail_price
                          : 0
                      ) -
                        parseInt(item.item_discount ? item.item_discount : 0)) *
                        parseInt(item.item_amt ? item.item_amt : 0)
                    )
                  }}
                </td>
                <td>
                  <div class="row">
                    <div class="col-sm-6 d-flex justify-content-md-end">
                      <button
                        type="button"
                        class="btn btn-link btn-color-muted btn-active-color-success p-0"
                        @click="goToEditItem(index)"
                      >
                        <i class="bi bi-pencil-square"></i>
                      </button>
                    </div>
                    <div class="col-sm-6">
                      <button
                        type="button"
                        class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                        @click="goToDeleteItem(index)"
                      >
                        <i class="bi bi-trash3-fill"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row d-flex justify-content-center mt-4">
          <div class="col-sm-8">
            <label for="selectMainProduct" class="form-label">หมายเหตุ</label>
            <input v-model="form.note" class="form-control mb-4" />
            <label for="selectMainProduct" class="form-label">เพิ่มที่</label>
            <select
              class="form-select mb-4"
              v-model="form.warehouseId"
              id="validationTooltip01"
              required
            >
              <option v-for="item in warehouses" :key="item" :value="item.id">
                {{ item.warehouse_code }} |
                {{ item.warehouse_name }}
              </option>
            </select>
          </div>

          <div class="col-sm-4">
            <label for="selectMainProduct" class="form-label"
              >มูลค่ารวม(ก่อนVAT)</label
            >
            <input
              v-model="form.total_price_before_vat"
              disabled
              class="form-control mb-4"
            />

            <!-- <label for="selectMainProduct" class="form-label">ส่วนลดรวม</label>
            <input v-model="form.discount" disabled class="form-control mb-4" />

            <label for="selectMainProduct" class="form-label">VAT</label>
            <input
              v-model="form.total_price_include_vat"
              disabled
              class="form-control mb-4"
            />

            <label for="selectMainProduct" class="form-label"
              >มูลค่าสุทธิ</label
            >
            <input
              v-model="form.total_price_summary"
              disabled
              class="form-control"
            /> -->
          </div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-12">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loading"
            >
              <div
                v-if="loading"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              บันทึก
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import AddItem from "./components/dialogAddItem.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";

import whApi from "@/api/warehouse/";
import userApi from "@/api/user/";
import moment from "moment";

import { Decode, Encode } from "@/services";

import numbro from "numbro";

export default {
  components: {
    AddItem,
  },
  setup() {
    document.title = "BELUCA | สร้างใบปรับสต๊อก";
  },
  data: () => ({
    userStock: "",

    searchProduct: "",

    dialogAddItem: false,
    dialogSalesman: false,

    loading: false,
    isSubmit: false,

    dataEditItem: {},
    indexEditItem: 0,
    warehouses: [],

    date: moment(new Date()),
    form: {
      cut_off_no: "",
      document_date: "",
      userId: "",
      warehouseId: "",
      note: "",
      products: [],
      expired_product: "0",
      status: "0",

      total_price_before_vat: 0,
      discount: 0,
      total_price_include_vat: 0,
      total_price_summary: 0,
    },
    ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
  }),

  created() {
    this.getWarehouse();
    this.getAllUser();
  },

  methods: {
    async getAllUser() {
      this.form.userId = Decode.decode(localStorage.getItem("boss"));
      let responseData = [];

      try {
        responseData = await userApi.user.getOne(this.form.userId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        const users = responseData.data;
        this.userStock = `${users.firstName} ${users.lastName}`;
      }
    },
    async getWarehouse() {
      let responseData = [];
      const companyId = localStorage.getItem("companyId");
      const companyBranchId = localStorage.getItem("companyBranchId");

      try {
        responseData = await whApi.warehouse.getAll({
          companyId: companyId,
          companyBranchId: companyBranchId,
        });
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.warehouses = responseData.data;
        this.form.warehouseId = this.warehouses[0].id;
      }
    },

    setDateBeforeSubmit() {
      this.form.document_date = moment(this.date._d).format("YYYY-MM-DD");
    },

    async submit() {
      let createResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;

      if (this.isValid) {
        this.loading = true;

        this.addWarehouseToItems();
        this.setDateBeforeSubmit();

        this.form = {
          ...this.form,
          companyId: parseInt(localStorage.getItem("companyId")),
          companyBranchId: parseInt(localStorage.getItem("companyBranchId")),
          user_name: this.userStock,
        };

        createResponse = await whApi.cutOffStock.create(this.form);
        if (createResponse.response_status === "SUCCESS") {
          Swal.fire({
            icon: "success",
            title: "เพิ่มสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/warehouse/WH6-3",
              query: {},
            });
          });
          this.loading = false;
        } else {
          Swal.fire({
            icon: "error",
            title: "เพิ่มไม่สำเร็จ !",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loading = false;
        }
      }
    },

    checkFormIsEmpty() {
      !this.form.warehouseId ? (this.isValid = false) : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            event.preventDefault();
            if (!form.checkValidity()) {
              event.stopPropagation();
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },

    addWarehouseToItems() {
      let newProducts = [];
      this.form.products.forEach((element) => {
        newProducts.push({
          ...element,
          warehouseId: this.form.warehouseId,
        });
      });
      this.form.products = newProducts;
    },

    cancel() {
      this.$router.go(-1);
    },

    // product
    addItem() {
      this.dialogAddItem = true;
      this.dataEditItem = {};
    },
    submitAddItem(item) {
      this.form.products.push(item);
      this.totalBeforeVat();
    },
    submitEditItem(item) {
      this.form.products.splice(this.indexEditItem, 1, item);
    },
    closeDialogAddItem() {
      this.dialogAddItem = false;
    },
    goToEditItem(index) {
      this.dataEditItem = this.form.products[index];
      this.indexEditItem = index;
      this.dialogAddItem = true;
    },
    goToDeleteItem(index) {
      Swal.fire({
        icon: "question",
        text: `ต้องการลบรายการที่ ${index + 1} สินค้าใช่หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (index > 0) {
            this.form.products.splice(index, 1);
          } else {
            this.form.products.shift();
          }
          this.totalBeforeVat();
        }
      });
    },
    //total
    totalBeforeVat() {
      let total = 0;
      let vat = 0;

      this.form.products.forEach((element) => {
        if (element.product_retail_price) {
          total +=
            parseFloat(element.product_retail_price) *
            parseFloat(element.item_amt);
          vat +=
            (parseFloat(element.product_retail_price) *
              parseFloat(element.item_amt) *
              7) /
            100;
        }
      });

      this.form.total_price_before_vat = parseFloat(total);

      this.form.total_price_include_vat = parseFloat(total) + vat;

      this.form.total_price_summary =
        this.form.total_price_include_vat +
        parseFloat(this.form.delivery_price ? this.form.delivery_price : 0) -
        parseFloat(this.form.discount ? this.form.discount : 0);

      // this.form.final_price = this.form.total_price_summary;
      this.form.final_price = parseFloat(total);
    },

    formatPrice(val) {
      if (val) {
        const newVal = numbro(val).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      } else {
        const newVal = numbro(0).format({
          thousandSeparated: true,
          mantissa: 2,
        });
        return newVal;
      }
    },
  },
};
</script>
<style scoped src="@/assets/custom-outside.css"></style>
